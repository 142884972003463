import Swiper from 'swiper';

window.initIndexSlider = () => {
	const $slider = document.querySelector('.js-index-slider');
	if (!$slider) return;

	return new Swiper($slider, {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    autoplay: true,
    navigation: {
      nextEl: $slider.parentNode.querySelector('[data-nav-next]'),
      prevEl: $slider.parentNode.querySelector('[data-nav-prev]'),
    },

		pagination: {
			el: $slider.parentNode.querySelector('.swiper-pagination')
		},
  });
}